import { createContext, useReducer } from 'react';

export const initialTriggerState = {
  showLogin: false,
  showSignup: false,
};

export const triggerReducer = (state, action) => {
  switch (action.type) {
    case 'TRIGGER_LOG_IN_MODAL':
      return {
        showLogin: action.payload,
      };
    case 'TRIGGER_SIGN_UP_MODAL':
      return {
        showSignup: action.payload,
      };
    default:
      return state;
  }
};

export const TriggerContext = createContext({
  state: { showLogin: false, showSignup: false },
  dispatch: () => {},
});

export const TriggerContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(triggerReducer, initialTriggerState);

  return <TriggerContext.Provider value={{ state, dispatch }}>{children}</TriggerContext.Provider>;
};
