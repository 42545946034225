import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

const BackDropStyled = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: #232323ff;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100%);
`;

const ModalStyled = styled.div`
  width: 30vw;
  height: 30vh;
  margin: auto;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Modal = ({ children, show }) => {
  const backDropRef = useRef(null);

  useEffect(() => {
    if (show === true) {
      gsap.to(backDropRef.current, {
        duration: 1,
        x: '0',
        y: '0',
      });
    }
    if (show === false) {
      gsap.to(backDropRef.current, {
        duration: 1,
        x: '0',
        y: '-110vh',
      });
    }
  }, [show]);

  return (
    <BackDropStyled ref={backDropRef}>
      <ModalStyled>{children}</ModalStyled>
    </BackDropStyled>
  );
};
