import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css';

import App from './App';

import { AuthContextProvider } from './context/AuthContext';
import { TriggerContextProvider } from './context/TriggerContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TriggerContextProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </TriggerContextProvider>
  </React.StrictMode>
);
