import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const NavbarLink = ({ name, imageLink }) => {
  const videoRef = useRef(null);
  const [hoverState, setHoverState] = useState('');

  const handleMouseEnter = () => {
    const video = videoRef.current;
    setHoverState('hovered');
    video.play();
  };

  useEffect(() => {
    console.log(hoverState);
  }, [hoverState]);

  const handleMouseExit = () => {
    setHoverState('');
    const video = videoRef.current;
    video.play();
  };

  const timeUpdateHandler = () => {
    const video = videoRef.current;
    if (hoverState === 'hovered' && video.currentTime > video.duration * 0.5) {
      console.log('Time Paused ------> ' + video.currentTime);
      video.pause();
    }
  };

  return (
    <div className="link-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseExit}>
      <Link to="/" className="nav-link animate-letter-space">
        <span className="link-text">{name}</span>
      </Link>
      <video
        className="video-player hover-video"
        style={{ width: '150px', height: '100px' }}
        muted
        playsInline
        onTimeUpdate={timeUpdateHandler}
        ref={videoRef}
      >
        <source src={imageLink} type="video/webm" />
      </video>
    </div>
  );
};

export default NavbarLink;
