import styled from 'styled-components';

export const Gradient = styled.div`
  position: absolute;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  width: ${(props) => props.width}%;
  height: ${(props) => props.height};
  top: ${(props) => props.position.top};
  left: ${(props) => props.position.left};
  right: ${(props) => props.position.right};
  z-index: ${(props) => props.zIndex};

  @media only screen and (max-width: 1600px) {
    width: 100%;
  }
`;

export const HeroStyles = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #01010f;

  .landing-container {
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding-top: 10em;
    /* outline: 1px solid red; */
  }

  .landing-left {
    max-width: 2000px;
    margin-left: 5vw;
    display: flex;
    /* outline: 1px solid greenyellow; */
    flex-direction: column;
  }

  .landing-header {
    font-family: 'Rajdhani', sans-serif;
    padding-top: 20px;
    width: 100%;
    /* outline: 1px solid red; */
  }

  .large-font {
    font-size: clamp(100px, 110px, 200px);
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.7));
    line-height: 10rem;
  }

  .landing-sub-header {
    font-size: clamp(20px, 30px, 50px);
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 4px;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.7));
    width: 70%;
    /* outline: 1px solid purple; */
  }

  .landing-sub-container {
    display: flex;
    margin-top: 50px;
    position: relative;
    /* border: 1px solid orange; */
  }

  .landing-right {
    width: 45vw;
    /* outline: 1px solid green; */
    display: flex;
    justify-content: center;
  }

  .video-player {
    width: 40vw;
    height: clamp(800px, 50vh, 1200px);
    z-index: 7;
    /* outline: 1px solid red; */
  }

  .riveSignUp {
    /* outline: 1px solid #ff00e6; */
    height: 5vh;
    width: 30%;
  }
`;
