import { Link, useNavigate } from 'react-router-dom';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useTriggerContext } from '../../hooks/useTriggerContext';
import { StyledSmall, NavDiv, NavBarStyles } from './NavBarStyles';
import { ButtonGlassMorph } from '../buttons/ButtonGlassMorph';
import { Login } from '../modal/login/Login';
import { Signup } from '../modal/signUp/Signup';
import { useRef } from 'react';
import { Modal } from '../modal/Modal';
import NavbarLink from './components/NavbarLink';
import navbarData from './data/navbarData';

const Navbar = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  let navigate = useNavigate();
  const { state, dispatch } = useTriggerContext();
  const loginBtnRef = useRef();
  const signUpBtnRef = useRef();

  const handleShowLogin = () => {
    loginBtnRef.current.blur();
    dispatch({ type: 'TRIGGER_LOG_IN_MODAL', payload: true });
    console.log(state);
  };

  const handleShowSignUp = () => {
    signUpBtnRef.current.blur();
    dispatch({ type: 'TRIGGER_SIGN_UP_MODAL', payload: true });
    console.log(state);
  };

  const handleCloseLogin = () => {
    dispatch({ type: 'TRIGGER_LOG_IN_MODAL', payload: false });
    console.log(state);
  };

  const handleCloseSignUp = () => {
    dispatch({ type: 'TRIGGER_SIGN_UP_MODAL', payload: false });
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <NavBarStyles>
      {/* -------------------------- Modals ----------------------------- */}
      {
        <Modal show={state.showLogin} onClick={handleCloseLogin} close={handleCloseLogin}>
          <Login close={handleCloseLogin} />
        </Modal>
      }
      {
        <Modal show={state.showSignup} onClick={handleCloseSignUp} close={handleCloseSignUp}>
          <Signup close={handleCloseSignUp} />
        </Modal>
      }
      {/* ------------------------------ Page Code --------------------------- */}
      <nav className="navBar">
        <div className="nav-inner">
          <div className="logo-container">
            <Link to="/" className="logo-link">
              <img
                src="https://res.cloudinary.com/dcx1zujst/image/upload/v1680228953/logo_fu6uym.png"
                className="logo"
                alt="Logo"
              />
            </Link>
            <span className="logoText">GUILDPOST</span>
          </div>
          <div className="nav-link-container">
            {navbarData.map((item) => (
              <NavbarLink name={item.name} imageLink={item.imageLink} key={item.id} />
            ))}
          </div>
          <nav className="nav-log-sign">
            {user && (
              <NavDiv>
                <StyledSmall>
                  <span>Logged In: {user.userName}</span>
                </StyledSmall>
                <button type="button" className="btn btn-primary" onClick={handleLogout}>
                  logout
                </button>
              </NavDiv>
            )}
            {!user && (
              <NavDiv>
                <ButtonGlassMorph>
                  <button onClick={handleShowLogin} ref={loginBtnRef}>
                    Login
                  </button>
                </ButtonGlassMorph>
                <ButtonGlassMorph>
                  <button onClick={handleShowSignUp} ref={signUpBtnRef}>
                    Sign Up
                  </button>
                </ButtonGlassMorph>
              </NavDiv>
            )}
          </nav>
        </div>
      </nav>
    </NavBarStyles>
  );
};

export default Navbar;
