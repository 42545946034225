import { TriggerContext } from '../context/TriggerContext';
import { useContext } from 'react';

export const useTriggerContext = () => {
  const context = useContext(TriggerContext);

  if (!context) {
    throw Error('useTriggerContext must be used inside an TriggerContextProvider');
  }

  return context;
};
