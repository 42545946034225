import styled from 'styled-components';

export const FeatureStyles = styled.section`
  width: 100%;
  position: relative;
  height: 100vh;
  background-color: #01010f;
  justify-content: center;
  display: flex;

  section {
    background-color: transparent;
  }

  .card-section {
    width: 70vw;
  }

  .card-container {
    display: flex;
    height: 100vh;
    justify-content: space-between;
    background-color: #ff030300;
    align-items: center;
    width: 100%;
  }

  .triangle-anim {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100vw;
    justify-self: flex-end;
    z-index: 0;
    background-color: transparent;
  }
`;
