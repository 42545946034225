import React, { useEffect, useRef, useContext } from 'react';
import { TriggerContext } from '../../context/TriggerContext';
import { HeroStyles, Gradient } from './HeroStyles';
import { FeatureStyles } from './FeatureStyles';
import { useRive, useStateMachineInput } from 'rive-react';
import Card from '../../components/cards/Card';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Landing = () => {
  const { dispatch } = useContext(TriggerContext);

  const STATE_MACHINE_NAME = 'State Machine 1';
  const INPUT_NAME = 'Hover Input Boolean';

  const videoElement = useRef(null);

  const { rive, RiveComponent } = useRive({
    src: 'signup.riv',
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  const onMouseOverInput = useStateMachineInput(rive, STATE_MACHINE_NAME, INPUT_NAME);

  const attemptPlay = () => {
    videoElement &&
      videoElement.current &&
      videoElement.current.play().catch((error) => {
        console.log('Error attempting to play', error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <HeroStyles>
        <Gradient
          img={
            'https://res.cloudinary.com/dcx1zujst/image/upload/v1680723245/DeepPurp-02_u9jfnt.png'
          }
          position={{ top: 0, left: 0 }}
          zIndex={1}
          width={70}
          height={'100vh'}
        />
        <Gradient
          img={
            'https://res.cloudinary.com/dcx1zujst/image/upload/v1680729326/rightGradient-02_qmintu.png'
          }
          position={{ top: 0, right: 0 }}
          zIndex={2}
          width={100}
          height={'150vh'}
        />
        <div className="landing-container">
          <div className="landing-left">
            <div></div>
            <h1 className="landing-header large-font">THE GUILD BUILDING POWERHOUSE</h1>
            <div className="landing-sub-container">
              <h2 className="landing-sub-header">
                Our node-based technology traps disorganization, simplifying your guild management!
              </h2>
              <div
                className="riveSignUp"
                onClick={() => dispatch({ type: 'TRIGGER_SIGN_UP_MODAL', payload: true })}
              >
                <RiveComponent onMouseEnter={() => onMouseOverInput.fire()} />
              </div>
            </div>
          </div>
          <div className="landing-right">
            <video className="video-player" ref={videoElement} autoPlay muted loop playsInline>
              <source
                src="https://res.cloudinary.com/dcx1zujst/video/upload/v1681876103/win_jyxvyb.webm"
                type="video/webm"
              />
            </video>
          </div>
        </div>
      </HeroStyles>
      <FeatureStyles>
        <section className="section">
          <div className="card-section">
            <div className="card-container">
              <Card card="card1" duration={'1'} />
              <Card card="card2" duration={'.7'} />
              <Card card="card3" duration={'.3'} />
            </div>
          </div>
          <div className="triangle-anim-container">
            <video
              className="video-player triangle-anim"
              ref={videoElement}
              autoPlay
              muted
              loop
              playsInline
            >
              <source
                src="https://res.cloudinary.com/dcx1zujst/video/upload/v1680964448/triangles_vkrcql.webm"
                type="video/webm"
              />
            </video>
          </div>
        </section>
      </FeatureStyles>
    </>
  );
};

export default Landing;
