const navbarData = [
  {
    name: 'Contact',
    imageLink: 'https://res.cloudinary.com/dcx1zujst/video/upload/v1693523325/Email_gjli0f.webm',
    id: 1,
  },
  {
    name: 'Plans',
    imageLink:
      'https://res.cloudinary.com/dcx1zujst/video/upload/v1693519620/PaymentIcon_tvjwor.webm',
    id: 2,
  },
  {
    name: 'Developers',
    imageLink: 'https://res.cloudinary.com/dcx1zujst/video/upload/v1693522523/DevIcon_vxfczy.webm',
    id: 3,
  },
];

export default navbarData;

//
