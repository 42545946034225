import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landing from './pages/Landing/Landing';
import Navbar from './components/navBar/Navbar';
import { createGlobalStyle } from 'styled-components';

function App() {
  const GlobalStyle = createGlobalStyle`
        * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      ${'' /* outline: 1px solid red;    */}
      font-family: 'Bebas Neue', cursive;
    }

      html {
        font-size: 10px;
      }

  `;

  return (
    <BrowserRouter>
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path="/" element={<Landing />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
