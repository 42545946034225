import { useState } from 'react';
import { useSignup } from '../../../hooks/useSignup';

export const Signup = ({ close }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const { signup, error, isLoading } = useSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await signup(email, password, userName);
  };

  return (
    <>
      {
        <div className="container">
          <form className="form-group-lg row w-100 h-50" onSubmit={handleSubmit}>
            <label htmlFor="email" className="form-label mt-4 fs-1">
              Email :
            </label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="fs-1"
            />
            <label htmlFor="password" className="form-label mt-4 fs-1">
              Password :
            </label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="fs-1"
            />
            <label htmlFor="userName" className="form-label mt-4 fs-1">
              User Name:
            </label>
            <input
              type="text"
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
              className="fs-1"
            />
            <div className="mt-4">
              <button
                type="submit"
                className="btn btn-primary me-2 fs-1"
                disabled={isLoading}
                onClick={close}
              >
                Sign Up
              </button>
              <button type="button" className="btn btn-primary fs-1" onClick={close}>
                Close
              </button>
              {error && <div className="error">{error}</div>}
            </div>
          </form>
        </div>
      }
    </>
  );
};
