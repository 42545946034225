import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

const CardStyled = styled.div`
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 70vh;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 50px;
`;

export const Card = ({ children, duration }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const cardElement = cardRef.current;
    gsap.fromTo(
      cardElement,
      {
        background: 'white',
        width: '20px',
        y: '50vh',
      },
      {
        duration: 0.5,
        background: 'rgba(255, 255, 255, 0.2)',
        width: '20vw',
        delay: '.5',
        y: 0,
        scrollTrigger: {
          trigger: cardElement,
        },
      }
    );
  }, []);

  return (
    <CardStyled ref={cardRef}>
      <div>{children}</div>
    </CardStyled>
  );
};

export default Card;
