import { useState } from 'react';
import { useLogin } from '../../../hooks/useLogin';

export const Login = ({ close }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, error, isLoading } = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      close();
    } catch (error) {
      console.log(`I'm the error ${error}`);
    }
  };

  return (
    <>
      {
        <form className="row form-group-lg w-100 h-50" onSubmit={handleSubmit}>
          <label htmlFor="email" className="form-label mt-4 fs-1">
            Email :
          </label>
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className="fs-1"
          />
          <label htmlFor="password" className="form-label mt-4 fs-1">
            Password :
          </label>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            className="fs-1"
          />
          <div className="mt-4">
            <button
              type="submit"
              className="btn btn-primary me-2 fs-1"
              disabled={isLoading}
              onClick={close}
            >
              Log in
            </button>
            <button type="button" className="btn btn-primary fs-1" onClick={close}>
              Close
            </button>
            {error && <div className="error">{error}</div>}
          </div>
        </form>
      }
    </>
  );
};
