import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  font-size: 3rem;
  width: 130px;
  height: 40px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #149450;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: ${(props) => props.background};
  border-radius: 3px;
  transition: 0.5s;
  overflow: hidden;

  button {
    background-color: transparent;
    width: 100%;
    border: 0;
  }

  &:hover {
    letter-spacing: 3px;
  }

  & :is(.glass-btn-light) {
    overflow: hidden;
  }

  & :is(.glass-btn-container)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translateX(0);
    transition: 0.5s;
  }

  & :is(.glass-btn-container):hover:before {
    transform: skewX(45deg) translateX(140%);
  }
`;

export const ButtonGlassMorph = ({ children, background }) => {
  return (
    <StyledButton background={background}>
      <div className="btn-light" />
      <div className="glass-btn-container">{children}</div>
    </StyledButton>
  );
};
