import styled from 'styled-components';

export const StyledSmall = styled.small`
  font-size: xx-large8;
  color: #4dd490;
  margin-right: 20px;
`;

export const NavDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .btn {
    font-size: 3rem;
    padding: 0 1rem;
  }

  button:nth-child(1) {
    margin-right: 30px;
  }
`;

export const NavBarStyles = styled.div`
  position: fixed;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 100vw;
  background: transparent;

  .navBar {
    display: flex;
    justify-content: center;
    background-color: #00000000;
    margin-top: 50px;
    /* outline: 1px solid hotpink; */
  }

  .nav-inner {
    display: flex;
    justify-content: space-between;
    background-color: #00000000;
    /* outline: 1px solid red; */
    width: 90vw;
  }

  .logo-link {
    background-color: transparent !important;
  }

  .logo {
    height: 35px;
    bottom: 0;
    padding-right: 10px;
    background-color: transparent !important;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.7));
  }

  .logoText {
    color: white;
    font-family: 'Bebas Neue';
    line-height: 100%;
    margin-bottom: -2px;
    font-size: 3em;
    background-color: #00000000;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.7));
  }

  .logo-container {
    border-radius: 10px;
    transition: box-shadow 0.5s ease-in-out;
    /* outline: 1px solid #ff6600; */
    align-items: start;
  }

  .nav-log-sign {
    background-color: transparent;
    /* outline: 1px solid #ff6600; */
    display: flex;
    align-items: start;
  }

  .userStatus {
    font-size: x-large;
  }

  .animate-letter-space {
    font-size: 25px;
    letter-spacing: 2px;
    transition: letter-spacing 0.5s ease-in-out;
  }

  .nav-link {
    display: block;
    width: 100%;
    opacity: 1;
    font-size: 2em;
    text-align: center;
    /* outline: 1px solid #807300; */
    position: relative;
    z-index: 2;
    transition: color 0.3s ease;
  }

  .nav-link-container {
    display: flex;
    justify-content: space-around;
    width: 35vw;
    /* outline: 1px solid red; */
  }

  .link-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    margin: 0 30px 0 30px;
    padding: 0 20px 0 20px;
    transition: box-shadow 0.5s ease-in-out;
    overflow: hidden;
  }

  .link-container:hover {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.18);
    transition: box-shadow 1s ease-in-out;
    transition: background-color 0.5s ease-in-out;
    border: 0.5s ease-in-out;
  }

  .link-text {
    letter-spacing: 2px;
    transition: letter-spacing 0.5s ease-in-out;
    color: #ffffffcb;
  }

  .link-container:hover .link-text {
    letter-spacing: 6px;
    animation-name: none;
  }
`;
