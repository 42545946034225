import { useAuthContext } from './useAuthContext';
import { useState } from 'react';

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const signup = async (email, password, userName) => {
    setIsLoading(true);
    setError(null);
    let json = '';

    try {
      const response = await fetch('https://guildpost-backend.onrender.com/api/users/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, userName }),
      });
      console.log('______Response_____');
      console.log(response);
      try {
        json = await response.json();
      } catch (error) {
        console.log(error);
      }
      if (!response.ok) {
        setIsLoading(false);
        setError(json.error);
      }
      if (response.ok) {
        localStorage.setItem('user', JSON.stringify(json));

        dispatch({ type: 'LOGIN', payload: json });

        setIsLoading(false);
      }
    } catch (error) {
      console.log(' error --> ' + error);
      let newError = error.toString();

      console.log(' newError ---> ' + newError);
      throw new Error(newError);
    }
  };

  return { signup, isLoading, error };
};

export default useSignup;
